export default {
  data: () => ({
    foundDimensions: false,
    ssrBoxSelector: '.template-thumbnail-box',
    boxDimensions: {
      width: null,
      height: null,
    },
    frameRequested: false,
  }),
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    updateDimensions(overrides) {
      const {
        selector = false,
        property = 'boxDimensions',
        updateGuard = 'foundDimensions',
      } = overrides || {};
      if (this[updateGuard]) return;
      const box = document.querySelector(selector || this.ssrBoxSelector);
      if (box) {
        const { width, height } = box.getBoundingClientRect();
        this[property] = { width, height };
        this[updateGuard] = true;
      }
    },
    handleResize() {
      if (this.frameRequested) return;
      window.requestAnimationFrame(() => {
        this.foundDimensions = false;
        this.updateDimensions();
        this.frameRequested = false;
      });
      this.frameRequested = true;
    },
  },
};
