export const COLOR = 'color';
export const AUTO_PERSONALIZE = 'autoPersonalize';
export const RECOMMENDATION = 'recommendation';
export const SIMILAR_TEMPLATES = 'similar-templates';
export const DISCOUNTS = 'discounts';
export const SEASONAL_SALES = 'seasonalSales';
export const SMART_BUILD_LIST = 'buildList';
export const PICK_THEME = 'pickTheme';
export const PICK_THEME_V2 = 'pickThemeV2';
export const BRAND_KIT = 'brandKit';
export const GOALS = 'goals';

const CUSTOM_THEME = 'customTheme';

const BASE_THEME_ID = 'baseThemeId';

const THEME = 'theme';

export const wizardFlow = (isAutoPersonalizedEnabled) => {
  const baseFlow = {
    [GOALS]: SMART_BUILD_LIST,
    [SMART_BUILD_LIST]: {
      email: DISCOUNTS,
      sms: DISCOUNTS,
      emailAndSms: DISCOUNTS,
      none: DISCOUNTS,
    },
    [DISCOUNTS]: {
      yes: SEASONAL_SALES,
      no: SEASONAL_SALES,
    },
    [SEASONAL_SALES]: {
      yes: PICK_THEME_V2,
      fewTimes: PICK_THEME_V2,
      no: PICK_THEME_V2,
    },
  };

  if (isAutoPersonalizedEnabled) {
    return {
      ...baseFlow,
      [PICK_THEME_V2]: AUTO_PERSONALIZE,
      [AUTO_PERSONALIZE]: BRAND_KIT,
      [BRAND_KIT]: RECOMMENDATION,
    };
  }
  return {
    ...baseFlow,
    [PICK_THEME_V2]: BRAND_KIT,
    [BRAND_KIT]: RECOMMENDATION,
  };
};

export const wizardOrder = (isAutoPersonalizeEnabled) => {
  if (isAutoPersonalizeEnabled) {
    return [
      GOALS,
      SMART_BUILD_LIST,
      DISCOUNTS,
      SEASONAL_SALES,
      PICK_THEME_V2,
      AUTO_PERSONALIZE,
      BRAND_KIT,
      RECOMMENDATION,
      SIMILAR_TEMPLATES,
    ];
  }
  return [
    GOALS,
    SMART_BUILD_LIST,
    DISCOUNTS,
    SEASONAL_SALES,
    PICK_THEME_V2,
    BRAND_KIT,
    RECOMMENDATION,
    SIMILAR_TEMPLATES,
  ];
};

const nonEcomFlow = (isAutoPersonalizedEnabled) => {
  if (isAutoPersonalizedEnabled) {
    return {
      [PICK_THEME_V2]: AUTO_PERSONALIZE,
      [AUTO_PERSONALIZE]: BRAND_KIT,
      [BRAND_KIT]: RECOMMENDATION,
    };
  }
  return {
    [PICK_THEME_V2]: BRAND_KIT,
    [BRAND_KIT]: RECOMMENDATION,
  };
};

const nonEcomOrder = (isAutoPersonalizeEnabled) => {
  if (isAutoPersonalizeEnabled) {
    return [PICK_THEME_V2, AUTO_PERSONALIZE, BRAND_KIT, RECOMMENDATION, SIMILAR_TEMPLATES];
  }
  return [PICK_THEME_V2, BRAND_KIT, RECOMMENDATION, SIMILAR_TEMPLATES];
};

export const percentage = (isAutoPersonalizedEnabled) => {
  if (isAutoPersonalizedEnabled) {
    return {
      [GOALS]: 33 / 4,
      [SMART_BUILD_LIST]: 33 * (2 / 4),
      [DISCOUNTS]: 33 * (3 / 4),
      [SEASONAL_SALES]: 35.4,
      [PICK_THEME_V2]: 33 + 33 / 3,
      [AUTO_PERSONALIZE]: 50,
      [BRAND_KIT]: 67.075,
      [RECOMMENDATION]: 103,
      [SIMILAR_TEMPLATES]: 103,
    };
  }

  return {
    [GOALS]: 33 / 4,
    [SMART_BUILD_LIST]: 33 * (2 / 4),
    [DISCOUNTS]: 33 * (3 / 4),
    [SEASONAL_SALES]: 35.4,
    [PICK_THEME_V2]: 33 + 33 / 2,
    [BRAND_KIT]: 67.075,
    [RECOMMENDATION]: 103,
    [SIMILAR_TEMPLATES]: 103,
  };
};

export const segmentSettings = () => [
  {
    name: 'business',
    icon: 'shop',
    startPage: GOALS,
    neededParams: [],
    text: 'onboarding.wizard.progress.yourBusiness.title',
    tooltip: 'onboarding.wizard.progress.yourBusiness.tooltip',
  },
  {
    name: 'brandKit',
    icon: 'brush-alt',
    startPage: PICK_THEME_V2,
    neededParams: [BASE_THEME_ID, COLOR, CUSTOM_THEME, THEME],
    text: 'onboarding.wizard.progress.themeKit.title',
    tooltip: 'onboarding.wizard.progress.themeKit.tooltip',
  },
  {
    name: 'useCase',
    icon: 'web-grid-alt',
    startPage: RECOMMENDATION,
    neededParams: [
      GOALS,
      SMART_BUILD_LIST,
      DISCOUNTS,
      SEASONAL_SALES,
      COLOR,
      PICK_THEME,
      BRAND_KIT,
      CUSTOM_THEME,
      BASE_THEME_ID,
      THEME,
    ],
    text: 'onboarding.wizard.progress.useCase.title',
    tooltip: 'onboarding.wizard.progress.useCase.tooltip',
  },
];

export const isWebShopIndustry = (businessType) => businessType === 'ecommerce';

export const getSmartWizardFlow = (businessType, isAutoPersonalizedEnabled) => {
  return isWebShopIndustry(businessType)
    ? wizardFlow(isAutoPersonalizedEnabled)
    : nonEcomFlow(isAutoPersonalizedEnabled);
};

export const getSmartWizardStepsOrder = (businessType, isAutoPersonalizedEnabled) => {
  return isWebShopIndustry(businessType)
    ? wizardOrder(isAutoPersonalizedEnabled)
    : nonEcomOrder(isAutoPersonalizedEnabled);
};

export const getStartingStep = (businessType) => {
  return isWebShopIndustry(businessType) ? GOALS : PICK_THEME_V2;
};

export const NAVIGATED_ON_SELECT = [SMART_BUILD_LIST, DISCOUNTS, SEASONAL_SALES, PICK_THEME];
